import { combineEpics } from "redux-observable";
import { APP_INIT, fetchMenusSuccess } from "./actions";
import { filter, switchMap, map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { fetchConfigSuccess } from "./actions";

const configsEpic = (action$: any) =>
  action$.pipe(
    filter((action: any) => action.type === APP_INIT),
    switchMap(() =>
      ajax.getJSON("https://origin.cms.videogamearchives.org/configs")
    ),
    map((configs: any[]) =>
      configs.reduce((results, config) => {
        results[config.key] = config.value;
        return results;
      }, {})
    ),
    map((config) => fetchConfigSuccess(config))
  );

const menuEpic = (action$: any) =>
  action$.pipe(
    filter((action: any) => action.type === APP_INIT),
    switchMap(() =>
      ajax.getJSON("https://origin.cms.videogamearchives.org/menus")
    ),
    map((menus: any[]) =>
      menus.reduce((results, menus) => {
        results[menus.name] = menus;
        return results;
      }, {})
    ),
    map((menus) => fetchMenusSuccess(menus))
  );

export default combineEpics(configsEpic, menuEpic);
