import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import appReducer from "./modules/app/reducer";
import appEpic from "./modules/app/epic";
import Config from "../models/config";
import ArticleConfig from "../models/articleConfig";
import { MenuInfo } from "../models/menu";
const epicMiddleware = createEpicMiddleware();

export interface AppState {
  articleConfig: ArticleConfig;
  config: Config;
  menus: MenuInfo;
}

const intialState = {
  articleConfig: { toc: [], sidebar: false },
  config: undefined,
  menus: undefined,
};

function configureStore(state = intialState) {
  const store = createStore(appReducer, state, applyMiddleware(epicMiddleware));
  epicMiddleware.run(appEpic);
  return store;
}

export default configureStore;
