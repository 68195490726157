import ArticleConfig from "../../../models/articleConfig";

export const APP_INIT = "APP_INIT";
export const FETCH_CONFIG_SUCCESS = "APP_FETCH_CONFIG_SUCCESS";
export const FETCH_MENUS_SUCCESS = "FETCH_MENUS_SUCCESS";
export const SET_ARTICLE_CONFIG = "SET_ARTICLE_CONFIG";

export function initAction() {
  return { type: APP_INIT };
}

export function fetchConfigSuccess(payload: any) {
  return { type: FETCH_CONFIG_SUCCESS, payload };
}

export function fetchMenusSuccess(payload: any) {
  return { type: FETCH_MENUS_SUCCESS, payload };
}

export function setArticleConfig(payload: ArticleConfig) {
  return { type: SET_ARTICLE_CONFIG, payload };
}
