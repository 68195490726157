import {
  FETCH_CONFIG_SUCCESS,
  FETCH_MENUS_SUCCESS,
  SET_ARTICLE_CONFIG,
} from "./actions";

export default (state: any, action: any) => {
  switch (action.type) {
    case FETCH_CONFIG_SUCCESS:
      return { ...state, config: action.payload };
    case FETCH_MENUS_SUCCESS:
      return { ...state, menus: action.payload };
    case SET_ARTICLE_CONFIG:
      return { ...state, articleConfig: action.payload };
    default:
      return state;
  }
};
