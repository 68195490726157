import React, { Component } from "react";
import frontMatter from "front-matter";
import ReactMarkdown from "react-markdown";
import Toc from "../../models/toc";
import { Link } from "react-router-dom";
import ArticleConfig from "../../models/articleConfig";

function flatten(text: string, child: any): string {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

interface Props {
  cloudfrontImagesUrl: string;
  source: string;
  setArticleConfig: (articleConfig: ArticleConfig) => void;
}

interface State {
  fm: {
    attributes: { [key: string]: any };
    body: string;
  };
}

class Markdown extends Component<Props, State> {
  private toc: Toc[] = [];

  state = {
    fm: { attributes: { sidebar: false, heroUrl: '' }, body: "" },
  };

  componentDidMount = () => {
    const sidebar = this.state.fm ? !!this.state.fm.attributes.sidebar : false;
    this.props.setArticleConfig({
      sidebar,
      toc: [...this.toc],
    });
  };

  static getDerivedStateFromProps(props: Props) {
    return {
      fm: frontMatter(props.source),
    };
  }

  transformImageUri = (url: string) => {
    const { cloudfrontImagesUrl } = this.props;
    if (!cloudfrontImagesUrl) {
      return url;
    }
    return url.replace(
      "wiki-strapi-cms-images.s3.amazonaws.com",
      cloudfrontImagesUrl
    );
  };

  headingRenderer = (props: { level: number; children: ChildNode }) => {
    const children = React.Children.toArray(props.children);
    const text = children.reduce(flatten, "");
    const slug = text.toLowerCase().replace(/\W/g, "-");
    this.toc.push({ level: props.level, label: text, slug });
    return React.createElement("h" + props.level, { id: slug }, props.children);
  };

  linkRenderer = (props: { href: string; children: any }) => {
    const { href, children } = props;
    const isYoutube = href.startsWith("https://www.youtube.com/embed/");
    const isBareLink = href === children.reduce(flatten, "");
    if (isYoutube && isBareLink) {
      return (
        <iframe width="560" height="315" title="YouTube" src={href}></iframe>
      );
    }

    const external =
      !href.includes("videogamearchives.org") && href.includes("http");
    if (external) {
      return (
        <a href={props.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }
    return <Link to={props.href}>{props.children}</Link>;
  };

  render() {
    const {
      fm: { body },
    } = this.state;

    let hero = null;
    const { heroUrl } = this.state.fm.attributes;
    
    if (heroUrl) {
      const url = this.transformImageUri(heroUrl);
      hero = (
        <div className="hero">
          <img src={url} />
        </div>
      )
    }

    const { transformImageUri, headingRenderer, linkRenderer } = this;
    return (
      <>
        {hero}
        <ReactMarkdown
          transformImageUri={transformImageUri}
          source={body}
          renderers={{ heading: headingRenderer, link: linkRenderer }}
        />
      </>
    );
  }
}

export default Markdown;
