import axios from "axios";
import { Article } from "../models/article";
import { Collection } from "../models/collection";

const baseUrl = "https://origin.cms.videogamearchives.org";

export const getHome = async () => {
  return await findArticleBySlug("/home");
};

export const getArticles = async () => {
  return await axios
    .get<Article[]>(`${baseUrl}/articles`)
    .then((res) => res.data);
};

export const findCollectionBySlug = async (slug: string) => {
  return await axios
    .get<Collection[]>(`${baseUrl}/collections`, { params: { slug } })
    .then((res) => res.data[0]);
};

export const findArticleById = async (id: number) => {
  return await axios
    .get<Article>(`${baseUrl}/articles/${id}`)
    .then((res) => res.data);
};

export const findArticleBySlug = async (slug: string) => {
  slug = slug === "/" ? "/articles/home" : slug;
  return await axios
    .get<Article[]>(`${baseUrl}/articles`, { params: { slug } })
    .then((res) => res.data[0]);
};
