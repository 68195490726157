import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";

import { findCollectionBySlug } from "../../services/client";
import { Collection } from "../../models/collection";

import "./Collections.css";
import Markdown from "../../components/Markdown";
import { connect } from "react-redux";
import { setArticleConfig } from "../../redux/modules/app/actions";
import ArticleConfig from "../../models/articleConfig";
import { AppState } from "../../redux/store";
import Toc from "../../models/toc";
import { Article } from "../../models/article";

enum ErrorStates {
  NotFound,
  ApiError,
}

interface Props extends RouteComponentProps {
  cloudfrontImagesUrl?: string;
  setArticleConfig: (articleConfig: ArticleConfig) => void;
}

interface State {
  isLoading: boolean;
  article?: Article;
  collection?: Collection;
  errorState: ErrorStates | undefined;
}

class Collections extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      article: undefined,
      collection: undefined,
      errorState: undefined,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { collectionSlug } = this.getSlugs();
    this.fetchCollection(collectionSlug);
  }

  private getSlugs() {
    const [_, collectionSlug = "", articleSlug = ""] =
      this.props.location.pathname.match(
        /(^\/collections\/.+)(\/articles\/.+)\/?/
      ) || [];

    return { collectionSlug, articleSlug: articleSlug.replace(/\/$/, "") };
  }

  async fetchCollection(slug: string) {
    try {
      const collection = await findCollectionBySlug(slug);
      if (!collection) {
        this.setState({
          errorState: ErrorStates.NotFound,
          isLoading: false,
        });
        return;
      }

      const article = this.getActiveArticle(collection);

      this.setState({
        article,
        collection,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ errorState: ErrorStates.ApiError });
    }
  }

  getActiveArticle(collection?: Collection) {
    if (!collection) {
      return undefined;
    }
    const { articleSlug } = this.getSlugs();
    const articleRef = collection.articles.find(
      (articleRef) => articleRef.article.slug === articleSlug
    );

    return articleRef?.article;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      const article = this.getActiveArticle(this.state.collection);
      this.setState({ isLoading: true, article: undefined }, () =>
        this.setState({ article, isLoading: false })
      );
    }
  }

  private setArticleConfig = (articleConfig: ArticleConfig) => {
    const { collection, article } = this.state;
    if (!collection || !article) {
      return;
    }
    const { collectionSlug } = this.getSlugs();

    const toc = collection.articles.reduce((results, item) => {
      if (item.article.id === article.id) {
        results.push(...articleConfig.toc);
      } else {
        results.push({
          level: 1,
          slug: `${collectionSlug}${item.article.slug}/`,
          label: item.internalName,
        });
      }
      return results;
    }, [] as Toc[]);

    const config = {
      toc,
      sidebar: true,
    };
    this.props.setArticleConfig(config);
  };

  render() {
    const { isLoading, article, errorState } = this.state;
    const { location, cloudfrontImagesUrl } = this.props;

    // loading handler
    if (isLoading || !cloudfrontImagesUrl) {
      return <h1>Loading...</h1>;
    }

    if (!article) {
      return <h1>Article Not Found.</h1>;
    }

    // error handler
    if (errorState) {
      switch (+errorState) {
        case ErrorStates.ApiError:
          return <div>Api Error</div>;
        case ErrorStates.NotFound:
          return <div>Not found</div>;
      }
    }
    return (
      <>
        <Helmet>
          <title>{article.title}</title>
          <link
            rel="canonical"
            href={`https://www.videogamearchives.org/${location.pathname}`}
          />
        </Helmet>
        <Markdown
          cloudfrontImagesUrl={cloudfrontImagesUrl}
          source={article.body}
          setArticleConfig={this.setArticleConfig}
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  cloudfrontImagesUrl: state.config && state.config.cloudfrontImagesUrl,
});

const mapDispatchToProps = (dispatch: any) => ({
  setArticleConfig: (articleConfig: ArticleConfig) =>
    dispatch(setArticleConfig(articleConfig)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter((props: Props) => <Collections {...props} />));
