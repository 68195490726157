import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import cn from "classnames";

import Articles from "./containers/Articles";
import Nav from "./containers/Nav";
import { initAction } from "./redux/modules/app/actions";
import Config from "./models/config";

import "./App.scss";
import ArticleConfig from "./models/articleConfig";
import Collections from "./containers/Collections";

interface Props {
  init: () => void;
  config: Config;
  articleConfig: ArticleConfig;
}

const withInitComponent = (App: any) => {
  class InitComponent extends Component<Props> {
    componentDidMount() {
      this.props.init();
    }

    render() {
      const { config } = this.props;
      if (!config) {
        return <h1>Fetching Config....</h1>;
      }
      return <App {...this.props}></App>;
    }
  }
  const mapStateToProps = (state: any) => ({
    config: state.config,
    articleConfig: state.articleConfig,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    init: () => dispatch(initAction()),
  });
  return connect(mapStateToProps, mapDispatchToProps)(InitComponent);
};

function App(props: Props) {
  const pageClass = cn("page", { "has-sidebar": props.articleConfig.sidebar });
  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.config.siteName}</title>
        <link rel="canonical" href="https://www.videogamearchives.org/" />
      </Helmet>
      <Router>
        <Nav />
        <main className={pageClass}>
          <div className="content">
            <Switch>
              <Route exact path="/">
                <Articles />
              </Route>
              <Route path="/articles/">
                <Articles />
              </Route>
              <Route path="/collections/">
                <Collections />
              </Route>
            </Switch>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default withInitComponent(App);
