import React, { PureComponent } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import AnchorLink from "react-anchor-link-smooth-scroll";
import Config from "../../models/config";
import { MenuInfo } from "../../models/menu";
import ArticleConfig from "../../models/articleConfig";
import { connect } from "react-redux";
import { AppState } from "../../redux/store";

import "./Nav.styles.scss";

interface Props {
  articleConfig: ArticleConfig;
  config?: Config;
  menus: MenuInfo;
}

interface State {
  isOpen: boolean;
  hasError: boolean;
}

class Nav extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      isOpen: false,
    };
  }

  getNavHeight = () => {
    return 57.59;
  };

  onClickMenu = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    const { articleConfig, config, menus } = this.props;
    if (!config) {
      return null;
    }

    if (!menus) {
      return null;
    }

    const { onClickMenu } = this;
    const { siteName } = config;
    const { top, side } = menus;
    const shouldShowSidebar = articleConfig.sidebar;
    const sidebarMobile = cn("sidebar", {
      "mobile-sidebar-open": this.state.isOpen,
      "has-sidebar": shouldShowSidebar,
    });
    return (
      <>
        <header className="navbar">
          <div className="sidebar-button" onClick={onClickMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              viewBox="0 0 448 512"
              className="icon"
            >
              <path
                fill="currentColor"
                d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"
              ></path>
            </svg>
          </div>
          <a href="/">
            <span className="site-name">{siteName}</span>
          </a>
          <div className="links can-hide">
            <nav className="nav-links">
              {top.links.map((link) => (
                <div className="nav-item" key={link.id}>
                  <Link to={link.url} className="nav-link">
                    {link.title}
                  </Link>
                </div>
              ))}
            </nav>
          </div>
        </header>
        <div className="sidebar-wrapper">
          <aside className={sidebarMobile}>
            <nav className="nav-links">
              {side.links.map((link) => (
                <div className="nav-item sidebar-nav" key={link.id}>
                  <Link to={link.url} className="nav-link">
                    {link.title}
                  </Link>
                </div>
              ))}
              {shouldShowSidebar && articleConfig.toc.length > 0 && (
                <div className="nav-separator sidebar-nav" />
              )}
              {shouldShowSidebar &&
                articleConfig.toc.map((item) => (
                  <div
                    className={`nav-item depth-${item.level}`}
                    key={item.slug}
                  >
                    {!item.slug.startsWith("/") && (
                      <AnchorLink
                        offset={this.getNavHeight}
                        href={`#${item.slug}`}
                        className="nav-link"
                      >
                        {item.label}
                      </AnchorLink>
                    )}
                    {item.slug.startsWith("/") && (
                      <Link to={item.slug} className="nav-link">
                        {item.label}
                      </Link>
                    )}
                  </div>
                ))}
            </nav>
          </aside>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  config: state.config,
  menus: state.menus,
  articleConfig: state.articleConfig,
});

export default connect(mapStateToProps)(Nav);
