import React, { PureComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";

import { findArticleBySlug } from "../../services/client";
import { Article } from "../../models/article";

import "./Articles.css";
import Markdown from "../../components/Markdown";
import ArticleConfig from "../../models/articleConfig";
import { connect } from "react-redux";
import { setArticleConfig } from "../../redux/modules/app/actions";
import { AppState } from "../../redux/store";

enum ErrorStates {
  NotFound,
  ApiError,
}

interface Props extends RouteComponentProps {
  cloudfrontImagesUrl?: string;
  setArticleConfig: (articleConfig: ArticleConfig) => void;
}

interface State {
  isLoading: boolean;
  article?: Article;
  errorState: ErrorStates | undefined;
}

class Articles extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorState: undefined,
      isLoading: false,
      article: undefined,
    };
  }

  componentDidMount() {
    this.fetchArticle(this.props.location.pathname);
  }

  async fetchArticle(slug: string) {
    this.setState({ isLoading: true });
    try {
      const article = await findArticleBySlug(slug);
      if (!article) {
        this.setState({
          article: undefined,
          errorState: ErrorStates.NotFound,
          isLoading: false,
        });
        return;
      }
      this.setState({ article, isLoading: false });
    } catch (error) {
      this.setState({ errorState: ErrorStates.ApiError });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const newPath = this.props.location.pathname;
    const oldPath = prevProps.location.pathname;
    if (oldPath !== newPath) {
      this.fetchArticle(newPath);
    }
  }

  private setArticleConfig = (articleConfig: ArticleConfig) => {
    this.props.setArticleConfig(articleConfig);
  };

  render() {
    const { isLoading, article, errorState } = this.state;
    const { cloudfrontImagesUrl } = this.props;

    // loading handler
    if (isLoading || !cloudfrontImagesUrl) {
      return <h1>Loading...</h1>;
    }

    if (!article) {
      return <h1>Article Not Found.</h1>;
    }

    // error handler
    if (errorState) {
      switch (+errorState) {
        case ErrorStates.ApiError:
          return <div>Api Error</div>;
        case ErrorStates.NotFound:
          return <div>Not found</div>;
      }
    }
    return (
      <>
        <Helmet>
          <title>{article.title}</title>
          <link
            rel="canonical"
            href={`https://www.videogamearchives.org/${article.slug}`}
          />
        </Helmet>
        <Markdown
          cloudfrontImagesUrl={cloudfrontImagesUrl}
          source={article.body}
          setArticleConfig={this.setArticleConfig}
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  cloudfrontImagesUrl: state.config && state.config.cloudfrontImagesUrl,
});

const mapDispatchToProps = (dispatch: any) => ({
  setArticleConfig: (articleConfig: ArticleConfig) =>
    dispatch(setArticleConfig(articleConfig)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter((props: Props) => <Articles {...props} />));
